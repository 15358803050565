<template>
  <div>
    <CRow>
      <CCol col="12">
        <CCard>
          <form id="Test" @submit.prevent="Test">
            <CCardHeader>
              <CCol col="6" style="font-size: 25px">إضافة منتج</CCol>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol sm="6">
                  <CInput
                    label="Name"
                    id="name"
                    v-model="Product.name"
                    placeholder="الاسم باللغة الانجليزية"
                    class="required"
                    @invalid="onInvalid"
                  />
                  <div v-if(errors.name) id="validateName" class="invalid-feedback">
                    {{errors.name}}
                  </div>
                </CCol>
                <CCol sm="6">
                  <CInput
                    label="الاسم"
                    id="name_ar"
                    v-model="Product.name_ar"
                    placeholder="الاسم باللغة العربية"
                    class="required"
                    @invalid="onInvalid"
                  />
                </CCol>
                <CCol sm="6">
                  <div class="form-group">
                    <label for="cities">
                      الماركات
                      <span class="star">*</span>
                    </label>
                    <multiselect
                      v-model="selected_brand"
                      :value="selected_brand"
                      id="brands"
                      :options="brands"
                      :searchable="true"
                      selectedLabel=" العنصر المحدد"
                      deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                      selectLabel="اضغط لإختيار هذا العنصر"
                      :close-on-select="true"
                      class="required"
                      placeholder="إختر الماركة   "
                      label="name"
                      track-by="name"
                      @invalid="onInvalid"
                    >
                      <span slot="noOptions">لا توجد ماركات</span>
                      <span slot="noResult">لا توجد نتيجة</span>
                    </multiselect>
                    <div v-if(errors.brand_id) id="validateBrandId" class="invalid-feedback">
                      {{errors.brand_id}}
                    </div>
                  </div>
                </CCol>
              </CRow>
              <br />
              <CRow>
                <CCol sm="12">
                  <CRow style="text-align: center; margin: 2% 0%">
                    <CBadge color="secondary" style="padding: 1.25em 1.4em">
                      ملحوظة : الترتيب مهم عند الاختيار</CBadge
                    >
                  </CRow>
                </CCol>
                <CCol sm="6">
                  <div class="form-group">
                    <label for="categories">
                      الفئات الرئيسية
                      <span class="star">*</span>
                    </label>

                    <div>
                      <multiselect
                        class="required"
                        v-model="selected_category"
                        :value="selected_category"
                        @select="onCategoriesChange($event)"
                        id="categories"
                        :options="categories"
                        :searchable="true"
                        selectedLabel=" العنصر المحدد"
                        deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                        selectLabel="اضغط لإختيار هذا العنصر"
                        :close-on-select="true"
                        placeholder="اختر اولا  "
                        label="name"
                        track-by="name"
                        required="required"
                      >
                        <span slot="noOptions">اللائحة فارغة</span>
                        <span slot="noResult">لا توجد نتيجة</span>
                      </multiselect>
                    </div>
                  </div>
                </CCol>
                <CCol sm="6">
                  <div class="form-group">
                    <label for="subCategories">
                      الفئات الفرعية
                      <span class="star">*</span>
                    </label>
                    <multiselect
                      v-model="selected"
                      :value="selected"
                      id="subCategories"
                      :options="subCategories"
                      :searchable="true"
                      selectedLabel=" العنصر المحدد"
                      deselectLabel="اضغط لإلغاء اختيار هذا العنصر"
                      selectLabel="اضغط لإختيار هذا العنصر"
                      :close-on-select="true"
                      class="required"
                      placeholder="أختار ثانيا  "
                      label="name"
                      track-by="name"
                      @invalid="onInvalid"
                      required
                    >
                      <span slot="noOptions">اللائحة فارغة</span>
                      <span slot="noResult">لا توجد نتيجة</span>
                    </multiselect>
                    <div v-if(errors.sub_category_id) id="validateSubCategoryId" class="invalid-feedback">
                      {{errors.sub_category_id}}
                    </div>
                  </div>
                </CCol>

                <CCol sm="6">
                  <CInput
                    type="number"
                    label="السعر"
                    id="price"
                    v-model="Product.price"
                    placeholder="ادخل سعر المنتج"
                    class="required"
                    @invalid="onInvalid"
                  />
                  <div v-if(errors.price) id="validatePrice" class="invalid-feedback">
                    {{errors.price}}
                  </div>
                </CCol>
                <CCol sm="6">
                  <CInput
                    type="number"
                    label="التخفيض"
                    id="discount"
                    v-model="Product.discount"
                    placeholder="أدخل نسبة التخفيض"
                    @invalid="onInvalid"
                  />
                </CCol>
              </CRow>
              <br />

              <CRow>
                <CCol sm="12">
                  <CTextarea
                    label="الوصف"
                    placeholder="المحتوي  "
                    horizontal
                    rows="9"
                    v-model="Product.description"
                    class="required"
                    required
                  />
                  <div v-if(errors.description) id="validateDescription" class="invalid-feedback">
                      {{errors.description}}
                    </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12">
                  <CTextarea
                    label="الوصف باللغة العربية"
                    placeholder="المحتوي  "
                    horizontal
                    rows="9"
                    v-model="Product.ar_description"
                    class="required"
                    required
                  />
                </CCol>
              </CRow>

              <CCol sm="12">
                <div class="form-group">
                  <label for="subCategories">
                    الصور
                    <span class="star">*</span>
                  </label>
                  <small class="form-text text-muted">حجم الصورة يجب ان لايتجاوز 5 ميقا</small>
                  <div v-if(errors.image) class="invalid-feedback">
                    {{errors.image}}
                  </div>
                  <div
                    id="my-strictly-unique-vue-upload-multiple-image "
                    style="display: flex; justify-content: center"
                  >
                    <vue-upload-multiple-image
                      @upload-success="uploadImageSuccess"
                      @before-remove="beforeRemove"
                      @edit-image="editImage"
                      :data-images="images"
                      idUpload="myIdUpload"
                      editUpload="myIdEdit"
                      dragText="اضغط لتحميل الصورة"
                      browseText="تصفح الصور"
                      primaryText=""
                      popupText=""
                      :maxImage="4"
                    >
                    </vue-upload-multiple-image>
                  </div>
                </div>
              </CCol>
            </CCardBody>
            <CCardFooter>
              <CButton type="submit" size="sm" color="primary">
                <CIcon name="cil-check-circle" /> حفظ
              </CButton>
              &emsp;
              <CButton type="reset" size="sm" color="danger">
                <CIcon name="cil-ban" /> مسح
              </CButton>
            </CCardFooter>
          </form>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script src="https://cdn.jsdelivr.net/npm/select2@4.1.0-rc.0/dist/js/select2.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js" integrity="sha512-uto9mlQzrs59VwILcLiRYeLKPPbS/bT71da/OEBYEwcdNUk8jYIy+D176RYoop1Da+f9mvkYrmj5MCLZWEtQuA==" crossorigin="anonymous"></script><script>

import VueUploadMultipleImage from 'vue-upload-multiple-image'
import swal from "sweetalert";
import $ from "jquery";
import 'jquery-ui';
import 'jquery-ui/ui/core';
import {
    freeSet
} from "@coreui/icons";

export default {

    name: "Test",
    data: function () {
        return {
            Product: {},
            image_1: "",
            image_2: "",
            image_3: "",
            image_4: "",
            image_5: "",
            categories: [],
            menu_categories: [],
            subCategories: [],
            brands: [],
            cities: [],
            selected: "",
            selected_area: "",
            selected_brand: "",
            selected_category: "",
            selected_brand: "",
            selected_city: "",
            mobile: [],
            address: [],
            mobile_index: "",
            description: [],
            areas: [],
            all_areas: [],
            branches: [],
            menu_items: [],
            menu_branches: [],
            images: [],
            options: [],
            errors: []
        };
    },
    components: {
        VueUploadMultipleImage
    },
    computed: {
        isInvalid() {
            return this.isTouched && this.value.length === 0
        }
    },
    created() {
        let categories = [];
        this.$http
            .get(`${process.env.VUE_APP_URL}categories`)
            .then((response) => {
                $.each(response.data.data, function (key, value) {
                    categories.push({
                        id: value.id,
                        name: value.name
                    });
                });
                this.categories = categories;
            });

        let subCategories = [];
        this.$http
            .get(`${process.env.VUE_APP_URL}sub-categories`)
            .then((response) => {
                $.each(response.data.data, function (key, value) {
                    subCategories.push({
                        id: value.id,
                        name: value.name
                    });
                });
                this.subCategories = subCategories;
            });

        let brands = [];
        this.$http
            .get(`${process.env.VUE_APP_URL}brands`)
            .then((response) => {
                $.each(response.data.data, function (key, value) {
                    brands.push({
                        id: value.id,
                        name: value.name
                    });
                });
                this.brands = brands;
            });
    },

    methods: {
        onCategoriesChange($event) {
            this.selected_category = $event.id;
            let subCategories = [];
            this.$http
                .get(
                    `${process.env.VUE_APP_URL}categories/${this.selected_category}/sub-categories`
                )
                .then((response) => {
                    $.each(response.data.data, function (key, value) {
                        subCategories.push({
                            id: value.id,
                            name: value.name
                        });
                    });
                    this.subCategories = subCategories;
                });
            
        },
        onFileChange(e) {
            this.Product.image = e.target.files[0];
            this.image = e.target.files[0];
        },
        addMobile: function (e) {
            this.mobile.push({
                value: ""
            });
        },
        addAddress: function () {
            this.address.push({
                value: ""
            });
        },
        Test: function (e) {
            this.errors = [];
            if (!this.Product.name) this.errors.push(`الاسم مطلوب`)
            if (!this.Product.price) this.errors.push(`السعر مطلوب`)
            if (!this.selected_brand) this.errors.push(`الماركة مطلوبة`)
            if (!this.selected_category) this.errors.push(`الفئة الرئيسية مطلوبة`)
            if (!this.selected) this.errors.push(`الفئة الفرعية مطلوبة`)
         
            if (this.errors.length) {
                swal({
                    title: this.errors[0],
                    buttons: "تم"
                });
                e.preventDefault();
                return false;
            }

            let currentObj = this;
            let formData = new FormData();
            formData.append("name", this.Product.name);
            formData.append("name_ar", this.Product.name_ar);
            formData.append("price", this.Product.price);

           if (this.image_1) {
              if (this.image_1.size > 5000000) {
                e.preventDefault();
                this.errors.push('image')
                this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
                swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
                return 0;
              } else {
                formData.append("image_1", this.image_1);
              }
            }
            if (this.image_2) {
              if (this.image_1.size > 5000000) {
                e.preventDefault();
                this.errors.push('image')
                this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
                swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
                return 0;
              } else {
                formData.append("image_2", this.image_2);
              }
            }
            if (this.image_3) {
              if (this.image_1.size > 5000000) {
                e.preventDefault();
                this.errors.push('image')
                this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
                swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
                return 0;
              } else {
                formData.append("image_3", this.image_3);
              }
            }
            if (this.image_4) {
              if (this.image_1.size > 5000000) {
                e.preventDefault();
                this.errors.push('image')
                this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
                swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
                return 0;
              } else {
                formData.append("image_4", this.image_4);
              }
            }
            if (this.image_5) {
              if (this.image_1.size > 5000000) {
                e.preventDefault();
                this.errors.push('image')
                this.errors.image = 'حجم الصورة تجاوز 5 ميقا'
                swal({ title: 'هناك خطأ في ادخال البيانات في حقل او اكثر', buttons: "تم" });
                return 0;
              } else {
                formData.append("image_5", this.image_5);
              }
            }
            if (this.Product.discount)
                formData.append("discount", this.Product.discount);
            if (this.selected.id)
                formData.append("sub_category_id", this.selected.id);
            if (this.selected_brand.id)
                formData.append("brand_id", this.selected_brand.id);
            if (this.Product.description)
                formData.append("description", this.Product.description);
            if (this.Product.ar_description)
                formData.append("description_ar", this.Product.ar_description);

            this.$http
                .post(`${process.env.VUE_APP_URL}products`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    currentObj.output = response.data;
                    if (!response.data.error) {
                        swal({
                            title: response.data.message,
                            buttons: "تم"
                        });
                        currentObj.$router.push({ path: "/products" });
                    } else {
                        currentObj.errors = response.data.data;
                    }
                })
                .catch(function (error) {
                    currentObj.output = error;
                });
        },
        onInvalid() {
            var element = $(this).closest('.ui-tabs-panel').index();
            $("#tabs").tabs().tabs('option', 'active', element)

        },
        onChange(branch, id) {
            this.menu_branches[id] = branch;
        },
        uploadImageSuccess(formData, index, fileList) {
            var file = dataURLtoFile(fileList[index].path, 'image_' + (index + 1));
            this.Product['image_' + (index + 1)] = file;
            this['image_' + (index + 1)] = file;
            console.log(this['image_' + (index + 1)]);
        },
        beforeRemove(index, done, fileList) {
            console.log('index', index, fileList)
            var r = confirm("remove image")
            if (r == true) {
                done()
            }
        },
        editImage(formData, index, fileList) {
            console.log('edit data', formData, index, fileList)
        }
    },
};

function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {
        type: mime
    });
}
</script>

<style>
#my-strictly-unique-vue-upload-multiple-image {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

h1,
h2 {
    font-weight: normal;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
